<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <router-link to="/module" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("menu.modules") }}
        </router-link>
    </div>
    <custom-table
        :title="$t('pages.module.popup.title')"
        :subTitle="$t('pages.module.popup.subTitle')"
        selectableRows
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:title="{ row: record }">
            {{ resolveDatum(record.translations, this.$root.defaultLanguage.id, 'language_id').title }}
        </template>
        <template v-slot:popupType="{ row: record }">
            {{ $t('pages.module.popup.popupTypes.' + record.options.popup_type) }}
        </template>
        <template v-slot:startDate="{ row: record }">
            {{ record.start_date ? $moment(record.start_date).format("DD.MM.YYYY - HH:mm:ss") : "-" }}
        </template>
        <template v-slot:endDate="{ row: record }">
            {{ record.end_date ? $moment(record.end_date).format("DD.MM.YYYY - HH:mm:ss") : "-" }}
        </template>
        <template v-slot:showDate="{ row: record }">
            {{ record.options.show_date ? $moment(record.options.show_date).format("DD.MM.YYYY - HH:mm:ss") : "-" }}
        </template>
        <template v-slot:showTimeout="{ row: record }">
            {{ record.options.show_timeout ? sprintf($t('pages.module.popup.status.secondText'), [record.options.show_timeout]) : $t('pages.module.popup.status.now') }}
        </template>
        <template v-slot:repeat="{ row: record }">
            {{ repeatText(record.options.repeat) }}
        </template>
        <template v-slot:hideHeader="{ row: record }">
            <span :class="record.options.hide_header ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.options.hide_header ? $t('common.yes') : $t('common.no') }}</span>
        </template>
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <router-link :to="'/module/popup/save/' + record.id" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                     <span class="svg-icon svg-icon-3">
                         <inline-svg src="/media/icons/duotune/art/art005.svg" />
                     </span>
                </router-link>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "product",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.popup.cols.title"),
                    scopedSlots: {customRender: "title"}
                },
                {
                    name: this.$t("pages.module.popup.cols.popupType"),
                    scopedSlots: {customRender: "popupType"}
                },
                {
                    name: this.$t("pages.module.popup.cols.startDate"),
                    scopedSlots: {customRender: "startDate"}
                },
                {
                    name: this.$t("pages.module.popup.cols.endDate"),
                    scopedSlots: {customRender: "endDate"}
                },
                {
                    name: this.$t("pages.module.popup.cols.showTimeout"),
                    scopedSlots: {customRender: "showTimeout"}
                },
                {
                    name: this.$t("pages.module.popup.cols.repeat"),
                    scopedSlots: {customRender: "repeat"}
                },
                {
                    name: this.$t("pages.module.popup.cols.hideHeader"),
                    scopedSlots: {customRender: "hideHeader"}
                },
                {
                    name: this.$t("common.sort"),
                    key: "sort",
                    class: ""
                },
                {
                    name: this.$t("common.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            selectedRowKeys: []
        }
    },
    computed: {
        table() {
            return this.$store.state.module.popup.table;
        }
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("pages.module.popup.title"), [this.$t("menu.moduleManagement")]);
        this.$store.dispatch('module/popup/get', {
            page: {},
            filterData: {
                sort: 'created_at:desc',
            }
        });
    },
    methods:{
        deleteRecord(id){
            this.$store.dispatch("module/popup/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.$router.push({
                        path: "/module/popup/save"
                    });
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("module/popup/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("module/popup/get", {
                page: pagination,
                filterData: Object.assign({
                    sort: 'created_at:desc'
                }, filterData)
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        },
        repeatText(repeat) {
            if(repeat != null){
                if(repeat == 0){
                    return this.$t('pages.module.popup.status.continually');
                }

                return this.sprintf(this.$t('pages.module.popup.status.secondText'), [repeat]);
            }else{
                return this.$t('pages.module.popup.status.once');
            }
        },
    }
}
</script>

<style scoped>

</style>